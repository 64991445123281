.loader {
  background: rgba(252, 252, 252, 0.7);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader .spinner {
  border: 5px solid #cccccc;
  border-top: 5px solid #ff6815;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  position: absolute;
  height: 30px;
  width: 30px;
  -webkit-animation: spin 1.7s linear infinite;
  animation: spin 1.7s linear infinite;
}