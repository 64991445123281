header {
    display: flex;
    border-bottom: 1px solid #E1E0E0;
    background-color: white;
    left: 0;
    width: 100%;
    height: 100%;
    color: black;
}

.center-logo {
    margin: 10px auto 19px auto !important;
    position: relative !important;
}

.center-logo>img {
    margin-top: 20% !important;
}

header>.logo-wrapper {
    position: absolute;
    margin-left: 5%;
}

header>.logo-wrapper>img {
    margin-top: 28%;
}

.stepper-container {
    width: 45%;
    margin: 10px auto 0 auto;
}

@media only screen and (max-width: 870px) {
    .stepper-container {
        width: 60%;
        margin-left: 25%;
    }
}

@media only screen and (max-width: 720px) {
    header>.logo-wrapper>img {
        height: 32px;
        margin-top: 38%;
    }
}

@media only screen and (max-width: 360px) {
    .stepper-container {
        width: 80% !important;
    }
    header>.logo-wrapper>img {
        height: 25px;
        width: 70px;
        margin-top: 50% !important;
    }
}

@media only screen and (max-width: 320px) {
  .stepper-container {
    width: 100% !important;
    margin-left: 0%;
    margin: 60px auto 0 auto;
  }
  header>.logo-wrapper {
    left: 50%;
    margin-left: -27px;
  }
}
