.social-login {
  margin-top: 32px;
  margin-bottom: 24px;
}

.button-logo {
  display: flex;
  width: 375px;
  height: 46px;
  margin: 15px auto 15px auto;
  background: none;
  cursor: pointer;
}

.button-logo-generic {
  border: 1px solid #7f7f7f;
  border-radius: 11px;
}

.button-logo-apple img {
  width: 15px;
  height: 18px;
  float: left;
}

.button-logo-apple25045 {
  border: none;
  padding: 0px;
  display: block;
}

.img-facebook {
  width: 15px;
  height: 15px;
  display: block;
  margin: auto 14px auto 24px;
}

.img-apple {
  width: 15px;
  height: 15px;
  display: block;
  margin: auto 14px auto 24px;
}

.img-google {
  width: 17px;
  height: 17px;
  display: block;
  margin: auto 8px auto 23px;
}

.span-generic {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.47059;
  font-style: normal;
  letter-spacing: -.022em;
  color: #2d2d2d;
}

.span-facebook {
  margin: auto 0px auto 2px;
}

.span-google {
  margin: auto 0px auto 7px;
}

.span-apple {
  margin: auto 0px auto 5px;
}

@media screen and (max-width: 499px) {

  .button-logo-apple img {
    width: auto;
    height: 17px;
    margin-left: 12px !important;
    margin-top: 1px !important;
    float: left;
  }
  .span-generic {
    font-size: 16px;
  }
  .img-facebook {
    width: 15px;
    margin: auto 10px auto 6px;
  }
  .img-google {
    width: 17px;
    margin: auto 4px auto 5px;
  }
  /* .img-apple {
    width: 17px;
    margin: auto 4px auto 5px;
  } */
}

@media screen and (max-width: 500px) {
  .img-google, .img-facebook {
    float: left;
    width: auto;
    margin: 4px 2px auto 13px;
  }
  .span-google {
    float: left;
  }
  .span-facebook {
    float: left;
    margin: auto 0px auto 9px;
  }
  .span-apple {
    float: left;
    margin-left: auto;
  }
  .button-logo-apple img {
    width: auto;
    height: 17px;
    margin-left: 10px;
    margin-top: 7px;
    float: left;
  }
}

@media screen and (max-width: 320px) {
  .img-google, .img-facebook .img-apple {
    margin: auto;
  }

  .button-logo-apple img {
    margin-top: 2px;
  }

  .span-apple {
    float: left;
    margin: auto 0px auto 10px;
  }
}
