.custom-link {
    margin: 10px 0 0;
}

.inline {
    margin: 0;
}

.custom-link span {
    color: #a4a4a4;
}

.custom-link a {
    color: #ee6b25;
    text-decoration: underline;
}

@media only screen and (max-width: 320px) {
    .link-wrapper a {        
        font-size: 1rem;
        margin-left: 37%;

    }    
}