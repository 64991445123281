.icon-step {
    margin: auto;
    font-size: 19px;
    font-style: normal;
    font-weight: bold;
    font-family: Source Sans Pro, sans-serif;
}

.icon-step>img {
    margin-left: 4px;
}

@media only screen and (max-width: 720px) {
    .icon-step {
        font-size: 16px;
        margin-bottom: 3px;
    }
}

@media only screen and (max-width: 360px) {
    .icon-step>img {
        margin-bottom: 1px;
    }
}