.terms {
  box-sizing: border-box;
  padding: 50px 15%;
}

.terms .title {
  box-sizing: border-box;
  padding: 0 40px;
  display: flex;
  position: relative;
}

.terms .title h1 {
  margin: 0;
  color: #ee6b25;
  font-size: 2.05rem;
  font-weight: 700;
  margin: 0 0 30px;
  text-align: left;
}

.terms .title .logo {
    position: absolute;
    top: 0;
    right: 40px;
    width: 64px;
  }

.terms .title .logo svg {
  fill: #949699;
}

.terms .text {
  border: 1px solid #717171;
  border-radius: 14px;
  box-sizing: border-box;
  color: #000;
  line-height: 1.2;
  padding: 46px 40px 23px;
  width: 100%;
}

.terms .text h2 {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0 0 7px;
  text-align: left;
}

.terms .text p {
  font-size: 1rem;
  font-weight: normal;
  margin: 0 0 20px;
}

.terms .logos {
  display: block;
  margin: 40px auto 0;
  max-height: 56px;
  max-width: 100%;
}

@media screen and (max-width: 800px) {

    .terms {
      padding: 40px 7%;
    }
}

@media screen and (max-width: 500px) {

  .terms {
    padding: 40px 25px;
  }

  .terms .title {
    padding: 0 25px;
  }

  .terms .title .logo {
    right: 25px;
  }

  .terms .text {
    padding: 30px 25px 15px;
  }
}