.bg-evento-hoje {
    background-color: #c64fdb;
  }
  .section.section-evento-hoje {
    width: 100%;
    height: 630px;
  }
  
  .section-evento-hoje {
    background-image: url('/img/bg-rd-hostel.png');
    background-position: top left;
    background-repeat:no-repeat;
    background-attachment:fill;
    top: calc(250px - 50vh);
    display: flex;
    justify-content: flex-end;
  }
  
  .section-evento-hoje .img-1 {
    position: absolute;
    width: 445px;
  }
  
  .section-evento-hoje .form {
    max-width: 400px;
    float: right;
    color: white;
    margin: 16px;
    margin-top: 40px;
    transition: all .3s
  }
  
  .section-evento-hoje .form img {
    width: 128px;
    border: 5px solid white;
    border-radius: 50rem;
    margin-left: 30%;
    margin-top: 40px;
  }
  
  .section-evento-hoje .form h2 {
    font-size: 38px;
    margin: 15px 0px;
  }
  
  .section-evento-hoje .form p {
    font-size: 18px;
  }
  
  .section-evento-hoje .form .form-group input[type="text"] {
    font-size: 14px;
    border-radius: 20px;
    height: 50px;
    color: #333333;
    background: #FFFFFF;
    border: unset;
    text-align: center;
    font-weight: bold;
    transition: all .3s
  }
  
  .section-evento-hoje .form .btn-submit {
    height: 50px;
    margin: auto;
    font-size: 18px;
    text-transform: unset;
    background: #50aa3e;
  }
  
  .section-evento-hoje .form .btn-submit:hover {
    height: 50px;
    margin: auto;
    font-size: 18px;
    text-transform: unset;
    background: #9ceb8c;
    color: darkgreen;
    transition: all .4s
  }
  
  .section-two-hora {
    min-height: 470px;
    height: 470px;
  }
  
  .section-two-hora .img-2 {
    margin: auto;
    width: 369px;
    height: 365px;
    margin-top:10%;
    /* background-image: url('/img/lp-banner-hora.png'); */
    background-image: url('/img/n-banner-nsctotal.png');
    background-repeat: round;
  }
  
  .section-two-hora .logos {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  .section-two-hora .section-two-hora-description {
    padding: 30px 16px;
    max-width: 512px;
  }
  
  .section-two-hora .section-two-hora-description h1 {
    font-size: 39px;
    color: #FF4100;
  }
  
  .section-two-hora .section-two-hora-description p {
    font-size: 16px;
    line-height: 22px;
  }
  
  .row {
    display: flex;
    flex-flow: row;
  }
  
  .collumn {
    width: 100%;
  }
  
  .title {
    padding-left: 22px;
  }
  
  .list li {
    padding-bottom: 10px;
  }
  
  @media(max-width: 500px) {
  
    .section-evento-hoje {
      background-image: url('/img/lp-banner-hora-1.png');
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-color: #147C00;
      top: calc(250px - 50vh);
      display: flex;
      justify-content: flex-end;
    }
  
    p {
        padding: 10px !important;
    }
  
    h1, h2 {
        padding: 10px !important;
    }
  
    button, input[type="text"] {
        width: 95% !important;
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
  
    .row {
        flex-flow: column;
    }
  
    .section, .section-evento-hoje, .section-two-hora {
        height: unset;
    }
  
    img {
        width: 100%;
    }
  
    .section-evento-hoje .img-1 {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: -1;
        width: 100%;
    }
  
    .section-evento-hoje .form {
        max-width: 100%;
        margin: auto;
    }
  
    .section-evento-hoje .form-box {
      height: 630px;
      background-color: #1a2926c7;
  }
  
    .section-two-hora .img-2 {
        width: 369px;
        height: 365px;
    }
  
  }
  