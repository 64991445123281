footer {
    display: flex;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #FF6915;
    color: #fff;
    height: 80px;
    z-index: 3;
}

footer .logo {
    width: 100%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
}

.logo>div>span {
    font-size: 12px;
    font-weight: bold;
}

.logo>img {
    margin: 2vh 0 1vh 0;
    width: auto;
}

@media only screen and (min-height: 800px) {
    footer {
        height: 12vh;
    }
}