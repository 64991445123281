.logos-wrapper {
    margin: 14px auto 24px -4%;
    width: 110%;
}

.logos-wrapper>.logos {
    display: block;
    max-height: 46px;
    width: 100%;
}

@media (max-width: 500px)
{
    .logos-wrapper {
        margin: 29px auto 33px 5%;
        width: 90%;
    }
}