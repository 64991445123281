.separator {
    align-items: center;
    display: flex;
    font-size: 1.1rem;
    margin: 15px 0 15px;
    width: 100%;
}

.separator hr {
    border: 0;
    border-top: 1px solid #000;
    display: block;
    flex-grow: 1;
    height: 1px;
    margin: 4px 0 0 0;
}

.separator .text {
    color: #000;
    padding: 0 25px;
    font-weight: bold;
}

@media screen and (max-width: 400px) {
    .separator .text {
        padding: 0 10px;
    }
}
