.text-page {
  box-sizing: border-box;
  padding: 50px 15%;
}

.text-page .title {
  box-sizing: border-box;
  padding: 0 40px;
  display: flex;
  position: relative;
}

.text-page .title h1 {
  margin: 0;
  color: #ee6b25;
  font-size: 2.05rem;
  font-weight: 700;
  margin: 0 0 30px;
  text-align: left;
}

.text-page .title .logo {
  position: absolute;
  top: 0;
  right: 40px;
  text-align: right;
}

.text-page .title .logo svg {
  fill: #949699;
  height: 54.77px;
  width: 64px;
}

.text-page .text {
  border: 1px solid #717171;
  border-radius: 14px;
  box-sizing: border-box;
  color: #717171;
  line-height: 1.2;
  padding: 46px 40px 23px;
  width: 100%;
}

.text-page .text h2 {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0 0 7px;
  text-align: left;
}

.text-page .text h2 {
  font-size: 1.05rem;
  font-weight: bold;
  margin: 0 0 7px;
  text-align: left;
}

.text-page .text p {
  font-size: 1rem;
  font-weight: normal;
  margin: 0 0 20px;
}

.text-page .text a {
  color: #ee6b25;
}

.text-page .text ul {
  margin: 0;
  padding: 0;
}

.text-page .text ul li {
  margin: 0 0 11px 0;
  list-style:none;
}


.text-page .logos {
  display: block;
  margin: 40px auto 0;
  max-height: 56px;
  max-width: 100%;
}

@media screen and (max-width: 800px) {

  .text-page {
    padding: 40px 7%;
  }
}

@media screen and (max-width: 500px) {

  .text-page {
    padding: 40px 25px;
  }

  .text-page .title {
    padding: 0 25px;
  }

  .text-page .title .logo {
    right: 25px;
  }

  .text-page .title h1 {
    font-size: 1.9rem;
  }

  .text-page .text {
    padding: 30px 25px 15px;
  }
}
