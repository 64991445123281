.forgot-email .checkbox-label .radio-button {
  margin-bottom: 2px;
}

.spam-alert-one {
  margin: auto;
  display: block;
  text-align: center;
  color: #ee6a26;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.1rem;
}

.spam-alert-two {
  text-align: center;
  font-size: 0.8rem;
  display: block;
  margin: auto;
  color: #848585;
  margin-bottom: 10px;
  max-width: 350px;
}

.spam-email {
  text-align: center;
  font-size: 1.5rem;
  display: block;
  margin: auto;
  color: #848585;
  margin-bottom: 5px;
}

.spam-alert-three {
  font-size: 0.8rem;
  display: block;
  margin: auto;
  color: #848585;
}

.spam-alert-last {
  color: #646565;
  display: block;
  margin: auto;
  text-align: center;
  font-size: 1rem;
}

.btn-submit-forgot-email {
  background-color: #ee6b25;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  margin: 15px 0 0;
  padding: 0.95rem;
  text-transform: uppercase;
  width: 100%;
}

.spam-fone-email {
  text-align: center;
  display: block;
  margin: auto;
  color: #747575;
  font-weight: bold;
  font-size: 1.1rem;
}

.spam-alert-or {
  text-align: center;
  font-size: 0.8rem;
  display: block;
  color: #848585;
  margin: 5px auto;
}
