.cancel-container {
    text-align: center;
    margin-top: 10px;
}

.cancel-container>.btn-cancel {
    color: #848585;
}

.change-mail .btn-submit {
    width: 80%;
    margin-left: 10%;
}