body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.social-login {
  margin-top: 32px;
  margin-bottom: 24px;
}

.button-logo {
  display: flex;
  width: 375px;
  height: 46px;
  margin: 15px auto 15px auto;
  background: none;
  cursor: pointer;
}

.button-logo-generic {
  border: 1px solid #7f7f7f;
  border-radius: 11px;
}

.button-logo-apple img {
  width: 15px;
  height: 18px;
  float: left;
}

.button-logo-apple25045 {
  border: none;
  padding: 0px;
  display: block;
}

.img-facebook {
  width: 15px;
  height: 15px;
  display: block;
  margin: auto 14px auto 24px;
}

.img-apple {
  width: 15px;
  height: 15px;
  display: block;
  margin: auto 14px auto 24px;
}

.img-google {
  width: 17px;
  height: 17px;
  display: block;
  margin: auto 8px auto 23px;
}

.span-generic {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.47059;
  font-style: normal;
  letter-spacing: -.022em;
  color: #2d2d2d;
}

.span-facebook {
  margin: auto 0px auto 2px;
}

.span-google {
  margin: auto 0px auto 7px;
}

.span-apple {
  margin: auto 0px auto 5px;
}

@media screen and (max-width: 499px) {

  .button-logo-apple img {
    width: auto;
    height: 17px;
    margin-left: 12px !important;
    margin-top: 1px !important;
    float: left;
  }
  .span-generic {
    font-size: 16px;
  }
  .img-facebook {
    width: 15px;
    margin: auto 10px auto 6px;
  }
  .img-google {
    width: 17px;
    margin: auto 4px auto 5px;
  }
  /* .img-apple {
    width: 17px;
    margin: auto 4px auto 5px;
  } */
}

@media screen and (max-width: 500px) {
  .img-google, .img-facebook {
    float: left;
    width: auto;
    margin: 4px 2px auto 13px;
  }
  .span-google {
    float: left;
  }
  .span-facebook {
    float: left;
    margin: auto 0px auto 9px;
  }
  .span-apple {
    float: left;
    margin-left: auto;
  }
  .button-logo-apple img {
    width: auto;
    height: 17px;
    margin-left: 10px;
    margin-top: 7px;
    float: left;
  }
}

@media screen and (max-width: 320px) {
  .img-google, .img-facebook .img-apple {
    margin: auto;
  }

  .button-logo-apple img {
    margin-top: 2px;
  }

  .span-apple {
    float: left;
    margin: auto 0px auto 10px;
  }
}

.links-container {
    display: flex;
    margin: 7% auto 7%;
}

.link-wrapper {
    display: flex;
    font-size: 1.3rem;
    margin: auto;
    color: #000;
}

/* @media only screen and (max-width: 480px) {
    .links-container {
        display: block;
    }
} */

@media only screen and (max-width: 320px) {
    .link-wrapper {
        font-size: 1rem;
        text-align: center;
        display: block;
    }
    .link-assinante {
        margin-left: 29%;
        font-size: 1rem;
    }
}

.call-us{
    margin-top: 1rem;
    text-align: center;
}

.link-whatsapp{
    color: #ee6b25;
    text-decoration: underline;
    font-size: 0.9rem;
}

.whatsapp{
    text-align: center;
    padding: 0.7rem;
}
.custom-link {
    margin: 10px 0 0;
}

.inline {
    margin: 0;
}

.custom-link span {
    color: #a4a4a4;
}

.custom-link a {
    color: #ee6b25;
    text-decoration: underline;
}

@media only screen and (max-width: 320px) {
    .link-wrapper a {        
        font-size: 1rem;
        margin-left: 37%;

    }    
}
.separator {
    align-items: center;
    display: flex;
    font-size: 1.1rem;
    margin: 15px 0 15px;
    width: 100%;
}

.separator hr {
    border: 0;
    border-top: 1px solid #000;
    display: block;
    flex-grow: 1;
    height: 1px;
    margin: 4px 0 0 0;
}

.separator .text {
    color: #000;
    padding: 0 25px;
    font-weight: bold;
}

@media screen and (max-width: 400px) {
    .separator .text {
        padding: 0 10px;
    }
}

.loader {
  background: rgba(252, 252, 252, 0.7);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.loader .spinner {
  border: 5px solid #cccccc;
  border-top: 5px solid #ff6815;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  position: absolute;
  height: 30px;
  width: 30px;
  -webkit-animation: spin 1.7s linear infinite;
  animation: spin 1.7s linear infinite;
}
.form-error {
    color: #dc3535;
    font-size: 1rem;
    margin: 12px 0 7px;
}
.logos-wrapper {
    margin: 14px auto 24px -4%;
    width: 110%;
}

.logos-wrapper>.logos {
    display: block;
    max-height: 46px;
    width: 100%;
}

@media (max-width: 500px)
{
    .logos-wrapper {
        margin: 29px auto 33px 5%;
        width: 90%;
    }
}
.box {
    padding: 10px;
    position: relative;
    max-width: 478px;
    min-height: calc(77vh - 70px);
    margin: 5vh auto;
}

.box>.back {
    position: absolute;
    top: 10px;
    left: 22px;
}

.box>.back svg {
    fill: #949699;
    height: 41.66px;
    /* IE */
    width: 72px;
}

.box>.back>a>span {
    position: absolute;
    top: 14px;
    left: 35px;
    color: #848585;
    font-size: 12px;
    font-weight: bold;
}

.box>.container {
    border: 1px solid #9b9b9b;
    border-radius: 14px;
    box-sizing: border-box;
    padding: 15px 40px 18px;
    width: 100%;
}

.box>.container>.title-wrapper {
    display: flex;
}

.box>.container>.title-wrapper>div {
    display: flex;
    width: 100%;
}

.box>.container>.title-wrapper>div>h1 {
    margin-left: auto;
    float: right;
}

.box>.container>.title-wrapper>div>img {
    margin: 17px auto auto 10px;
    float: left;
}

.box>.container>.title-wrapper>div>h1.center {
    margin-right: auto;
}



.message-container>.message {
    color: #FF6915;
    font-size: 16px;
    width: 90%;
    margin: auto;
    padding-bottom: 2%;
    text-align: center;
    padding-left: 6px;
}

.success-icon {
    background: url("/icons/success-icon.svg");
    height: 19px;
    width: 18px;
    display: inline-block;
    position: absolute;
}

.error-icon {
    background: url("/icons/error-icon.svg");
    height: 19px;
    width: 18px;
    display: inline-block;
    position: absolute;
}

@media screen and (max-width: 720px) {
    .box {
        min-height: 77vh;
    }
}

@media screen and (max-width: 550px) {
    .box>.container>.title-wrapper>div>img {
        display: block;
        margin: auto;
        max-width: 125px;
    }
    .box {
        margin: 0 auto;
        padding: 20px;
    }
    .box>.logo {
        top: 35px;
        right: 35px;
    }
    .box>.container {
        padding: 36px 25px 12px;
    }
}

@media screen and (max-width: 400px) {
    .box>.logo {
        top: 25px;
        right: 25px;
    }
    .box {
        padding: 10px;
    }
    .box>.container {
        padding: 36px 20px 12px;
    }
}

.box>.footer {
    box-sizing: border-box;
    font-size: 0.9rem;
    padding: 4px 15px 10px;
    text-align: right;
    width: 100%;
    display: flex;
}

.box>.footer .left {
    text-align: left;
    width: 50%;
}

.box>.footer .right {
    text-align: right;
    width: 50%;
}

.box>.footer .link {
    color: #858585;
    font-weight: bold;
    text-decoration: none;
}

.box h1 {
    color: #ee6b25;
    font-size: 2.05rem;
    font-weight: 700;
    margin: 10px 0 22px;
    text-align: center;
}

.box h2 {
    color: #000;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0 0 10px;
    text-align: center;
    width: 95%;
}

.center360 {
    margin-left: inherit;
}





.signup .terms-message {
    color: #999999;
    font-size: 0.95rem;
    margin: 15px 0 5px;
}

.signup .terms-message a {
    color: #00C8FE;
    text-decoration: none;
}

.signup .user-exists {
    margin: 20px 0 0;
    color: #dc3535;
    font-size: 1rem;
}

.signup .user-exists a {
    color: #0068f0;
}

.signup .account-confirmation-text {
    text-align: left;
    color: #999999;
    margin-bottom: 1vh;
    font-size: 1.0rem;
    font-weight: 500;
    margin-top: 6px;
}

.signup .alinhamento {
    padding: 0;
    margin: 0;
}
.checkbox {
  display: inline-block;
  margin: 0 0 10px 0;
}

.checkbox.large {
  margin: 10px 0 5px 0;
}

.checkbox input {
  display: none;
  height: 0;
  width: 0;
}

.checkbox label {
  font-size: 1rem;
  color: #8e8e8e;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.checkbox.large label {
  font-size: 1.05rem;
}

.checkbox label a {
  color: #0068f0;
}

.checkbox label .check {
  border: 1px solid #C0C0C0;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 14px;
  margin: 0 5px -1px 0;
  position: relative;
  width: 14px;
}

.checkbox.large label .check {
  border-radius: 3px;
  height: 20px;
  margin: 0 7px -4px 0;
  position: relative;
  width: 20px;
}

.checkbox.error label .check {
  border-color: #dc3535;
}

.checkbox input:checked + label .check:after {
  content: '';
  position: absolute;
  left: 1px;
  top: 5px;
  background: #555555;
  width: 2px;
  height: 2px;
  box-shadow:
    2px 0 0 #555555,
    4px 0 0 #555555,
    4px -2px 0 #555555,
    4px -4px 0 #555555,
    4px -6px 0 #555555,
    4px -8px 0 #555555;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);    
}

.checkbox.large input:checked + label .check:after {
  left: 4px;
  top: 8px;
  background: #555555;
  box-shadow: 
    2px 0 0 #555555,
    4px 0 0 #555555,
    4px -2px 0 #555555,
    4px -4px 0 #555555,
    4px -6px 0 #555555,
    4px -8px 0 #555555;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);    
}



.forgot-email .checkbox-label .radio-button {
  margin-bottom: 2px;
}

.spam-alert-one {
  margin: auto;
  display: block;
  text-align: center;
  color: #ee6a26;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.1rem;
}

.spam-alert-two {
  text-align: center;
  font-size: 0.8rem;
  display: block;
  margin: auto;
  color: #848585;
  margin-bottom: 10px;
  max-width: 350px;
}

.spam-email {
  text-align: center;
  font-size: 1.5rem;
  display: block;
  margin: auto;
  color: #848585;
  margin-bottom: 5px;
}

.spam-alert-three {
  font-size: 0.8rem;
  display: block;
  margin: auto;
  color: #848585;
}

.spam-alert-last {
  color: #646565;
  display: block;
  margin: auto;
  text-align: center;
  font-size: 1rem;
}

.btn-submit-forgot-email {
  background-color: #ee6b25;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  margin: 15px 0 0;
  padding: 0.95rem;
  text-transform: uppercase;
  width: 100%;
}

.spam-fone-email {
  text-align: center;
  display: block;
  margin: auto;
  color: #747575;
  font-weight: bold;
  font-size: 1.1rem;
}

.spam-alert-or {
  text-align: center;
  font-size: 0.8rem;
  display: block;
  color: #848585;
  margin: 5px auto;
}

.terms {
  box-sizing: border-box;
  padding: 50px 15%;
}

.terms .title {
  box-sizing: border-box;
  padding: 0 40px;
  display: flex;
  position: relative;
}

.terms .title h1 {
  margin: 0;
  color: #ee6b25;
  font-size: 2.05rem;
  font-weight: 700;
  margin: 0 0 30px;
  text-align: left;
}

.terms .title .logo {
    position: absolute;
    top: 0;
    right: 40px;
    width: 64px;
  }

.terms .title .logo svg {
  fill: #949699;
}

.terms .text {
  border: 1px solid #717171;
  border-radius: 14px;
  box-sizing: border-box;
  color: #000;
  line-height: 1.2;
  padding: 46px 40px 23px;
  width: 100%;
}

.terms .text h2 {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0 0 7px;
  text-align: left;
}

.terms .text p {
  font-size: 1rem;
  font-weight: normal;
  margin: 0 0 20px;
}

.terms .logos {
  display: block;
  margin: 40px auto 0;
  max-height: 56px;
  max-width: 100%;
}

@media screen and (max-width: 800px) {

    .terms {
      padding: 40px 7%;
    }
}

@media screen and (max-width: 500px) {

  .terms {
    padding: 40px 25px;
  }

  .terms .title {
    padding: 0 25px;
  }

  .terms .title .logo {
    right: 25px;
  }

  .terms .text {
    padding: 30px 25px 15px;
  }
}
.text-page {
  box-sizing: border-box;
  padding: 50px 15%;
}

.text-page .title {
  box-sizing: border-box;
  padding: 0 40px;
  display: flex;
  position: relative;
}

.text-page .title h1 {
  margin: 0;
  color: #ee6b25;
  font-size: 2.05rem;
  font-weight: 700;
  margin: 0 0 30px;
  text-align: left;
}

.text-page .title .logo {
  position: absolute;
  top: 0;
  right: 40px;
  text-align: right;
}

.text-page .title .logo svg {
  fill: #949699;
  height: 54.77px;
  width: 64px;
}

.text-page .text {
  border: 1px solid #717171;
  border-radius: 14px;
  box-sizing: border-box;
  color: #717171;
  line-height: 1.2;
  padding: 46px 40px 23px;
  width: 100%;
}

.text-page .text h2 {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0 0 7px;
  text-align: left;
}

.text-page .text h2 {
  font-size: 1.05rem;
  font-weight: bold;
  margin: 0 0 7px;
  text-align: left;
}

.text-page .text p {
  font-size: 1rem;
  font-weight: normal;
  margin: 0 0 20px;
}

.text-page .text a {
  color: #ee6b25;
}

.text-page .text ul {
  margin: 0;
  padding: 0;
}

.text-page .text ul li {
  margin: 0 0 11px 0;
  list-style:none;
}


.text-page .logos {
  display: block;
  margin: 40px auto 0;
  max-height: 56px;
  max-width: 100%;
}

@media screen and (max-width: 800px) {

  .text-page {
    padding: 40px 7%;
  }
}

@media screen and (max-width: 500px) {

  .text-page {
    padding: 40px 25px;
  }

  .text-page .title {
    padding: 0 25px;
  }

  .text-page .title .logo {
    right: 25px;
  }

  .text-page .title h1 {
    font-size: 1.9rem;
  }

  .text-page .text {
    padding: 30px 25px 15px;
  }
}

.background {
    position: fixed; /* Stay in place */
    z-index: 2; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.dialog {
    border-radius: 10px;
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    /* padding: 20px; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; 
}

.close-btn {
    z-index: 1;
    position: relative;
    margin-right: 20px;
    margin-top: 20px;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.section {
    width: 100%;
    height: 527.75px;
}

.section-one .form-box {min-height: 444px;}

.bg-unisul {background-color: #1c408a;}

.section-one .img-1 {
    position: absolute;
    width: 445px;
}

.section-one .form {
  max-width: 400px;
  float: right;
  color: white;
  margin: 16px;
  margin-top: 40px;
  transition: all .3s
}

.section-one .form img {
    width: 167px;
}

.section-one .form h2 {
    font-size: 38px;
    margin: 15px 0px;
}

.section-one .form p {
    font-size: 18px;
}

.section-one .form .form-group input[type="text"] {
    border-radius: 20px;
    height: 50px;
    color: white;
    background: #5A6FA3;
    border: unset;
    text-align: center;
}

.section-one .form .btn-submit {
    height: 50px;
    margin: unset;
    font-size: 20px;
    text-transform: unset;
}

.section-two {
    min-height: 470px;
    height: 470px;
}

.section-two .img-2 {
    margin: auto;
    width: 500px;
    height: 353px;
    background-image: url('/img/lp-banner-2.png');
    background-repeat: round;
}

.section-two .logos {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.section-three .img-3 {
    margin: 61px auto 0px auto;
    width: 433px;
}

.section-three .section-three-description {
    padding-top: 105px;
    max-width: 527px;
    margin: auto;
    color: white;
}

.section-three .section-three-description h1 {
    font-size: 39px;
    margin: unset;
}

.section-three .section-three-description p {
    font-size: 16px;
    line-height: 22px;
}

.section-three .btn-submit {
    height: 50px;
    width: 380px;
    margin: 30px 0;
    font-size: 20px;
    background-color: #6F3989;
    text-transform: unset;
}

.section-three .btn-submit:hover {
    height: 50px;
    width: 380px;
    margin: 30px 0;
    background-color: #6F3989;
    text-transform: unset;
}

.section-four {
    padding-top: 95px;
    height: unset;
}

.section-four .img-4 {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 490px;
}

.section-four .section-four-description {
    max-width: 379px;
    margin-bottom: 20px;
    float: left;
}

.section-four .section-four-description h1 {
    font-size: 35px;
    margin: unset;
    color: #FF4100;
}

.section-four .section-four-description p {
    font-size: 16px;
    line-height: 22px;
}

.section-four .download-app p {
    margin: 10px 0px;
    font-size: 20px;
    font-weight: 700;
    color: #BEBEBE;
}

.section-four .download-app img {
    width: 124px;
    margin-right: 10px;
    height: 36px;
}

.row {
    display: flex;
    flex-flow: row;
}

.collumn {
    width: 100%;
}

.title {
    padding-left: 22px;
}

.list li {
    padding-bottom: 10px;
}

@media(max-width: 500px) {

    p {
        padding: 10px !important;
    }

    h1, h2 {
        padding: 10px !important;
    }

    button, input[type="text"] {
        width: 95% !important;
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;

    }

    .row {
        flex-flow: column;
    }

    .section, .section-one, .section-two, .section-three, .section-four {
        height: unset;
    }

    img {
        width: 100%;
    }

    .section-one .img-1 {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: -1;
        width: 100%;
    }

    .section-one .form {
        max-width: 100%;
        margin-right: unset;
    }

    .section-one .form-box {
        height: 570px;
        background-color: #1c408a7a;
    }

    .section-one .form img {
        width: 95%;
        display: block;
        margin: auto;
    }

    .section-two .img-2 {
        width: auto;
        height: 300px;
    }

    .section-three {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
    }

    .section-three .img-3 {
        margin: 61px auto -4px auto;
        width: 100%;
    }

    .section-three .btn-submit {
        width: 100%;
    }

    .section-four {
        flex-flow: column-reverse;
    }

    .section-four .img-4 {
        width: 100%;
    }

    .section-four .section-four-description {
        max-width: unset;
    }

    .section-four .download-app .row {
        flex-flow: row;
    }

    .section-four .download-app p {
        margin: unset;
    }

    .section-four .download-app {
        padding-left: 20px;
    }

}

.bg-hora {
  background-color: #379424;
}
.section.section-one-hora {
  width: 100%;
  height: 630px;
}

.section-one-hora {
  background-image: url('/img/logoHora.png');
  background-position: top left;
  background-repeat:no-repeat;
  background-attachment:fill;
  top: calc(250px - 50vh);
  display: flex;
  justify-content: flex-end;
}

.section-one-hora .img-1 {
  position: absolute;
  width: 445px;
}

.section-one-hora .form {
  max-width: 400px;
  float: right;
  color: white;
  margin: 16px;
  margin-top: 40px;
  transition: all .3s
}

.section-one-hora .form img {
  width: 128px;
  border: 5px solid white;
  border-radius: 50rem;
  margin-left: 30%;
  margin-top: 40px;
}

.section-one-hora .form h2 {
  font-size: 38px;
  margin: 15px 0px;
}

.section-one-hora .form p {
  font-size: 18px;
}

.section-one-hora .form .form-group input[type="text"] {
  font-size: 14px;
  border-radius: 20px;
  height: 50px;
  color: #333333;
  background: #FFFFFF;
  border: unset;
  text-align: center;
  font-weight: bold;
  transition: all .3s
}

.section-one-hora .form .btn-submit {
  height: 50px;
  margin: auto;
  font-size: 18px;
  text-transform: unset;
  background: #50aa3e;
}

.section-one-hora .form .btn-submit:hover {
  height: 50px;
  margin: auto;
  font-size: 18px;
  text-transform: unset;
  background: #9ceb8c;
  color: darkgreen;
  transition: all .4s
}

.section-two-hora {
  min-height: 470px;
  height: 470px;
}

.section-two-hora .img-2 {
  margin: auto;
  width: 369px;
  height: 365px;
  margin-top:10%;
  /* background-image: url('/img/lp-banner-hora.png'); */
  background-image: url('/img/n-banner-nsctotal.png');
  background-repeat: round;
}

.section-two-hora .logos {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.section-two-hora .section-two-hora-description {
  padding: 30px 16px;
  max-width: 512px;
}

.section-two-hora .section-two-hora-description h1 {
  font-size: 39px;
  color: #FF4100;
}

.section-two-hora .section-two-hora-description p {
  font-size: 16px;
  line-height: 22px;
}

.row {
  display: flex;
  flex-flow: row;
}

.collumn {
  width: 100%;
}

.title {
  padding-left: 22px;
}

.list li {
  padding-bottom: 10px;
}

@media(max-width: 500px) {

  .section-one-hora {
    background-image: url('/img/lp-banner-hora-1.png');
    background-position: top left;
    background-size: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: #147C00;
    top: calc(250px - 50vh);
    display: flex;
    justify-content: flex-end;
  }

  p {
      padding: 10px !important;
  }

  h1, h2 {
      padding: 10px !important;
  }

  button, input[type="text"] {
      width: 95% !important;
      display: block !important;
      margin-left: auto !important;
      margin-right: auto !important;
  }

  .row {
      flex-flow: column;
  }

  .section, .section-one-hora, .section-two-hora {
      height: unset;
  }

  img {
      width: 100%;
  }

  .section-one-hora .img-1 {
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -1;
      width: 100%;
  }

  .section-one-hora .form {
      max-width: 100%;
      margin: auto;
  }

  .section-one-hora .form-box {
    height: 630px;
    background-color: #1a2926c7;
}

  .section-two-hora .img-2 {
      width: 369px;
      height: 365px;
  }

}

.bg-evento-hoje {
    background-color: #c64fdb;
  }
  .section.section-evento-hoje {
    width: 100%;
    height: 630px;
  }
  
  .section-evento-hoje {
    background-image: url('/img/bg-rd-hostel.png');
    background-position: top left;
    background-repeat:no-repeat;
    background-attachment:fill;
    top: calc(250px - 50vh);
    display: flex;
    justify-content: flex-end;
  }
  
  .section-evento-hoje .img-1 {
    position: absolute;
    width: 445px;
  }
  
  .section-evento-hoje .form {
    max-width: 400px;
    float: right;
    color: white;
    margin: 16px;
    margin-top: 40px;
    transition: all .3s
  }
  
  .section-evento-hoje .form img {
    width: 128px;
    border: 5px solid white;
    border-radius: 50rem;
    margin-left: 30%;
    margin-top: 40px;
  }
  
  .section-evento-hoje .form h2 {
    font-size: 38px;
    margin: 15px 0px;
  }
  
  .section-evento-hoje .form p {
    font-size: 18px;
  }
  
  .section-evento-hoje .form .form-group input[type="text"] {
    font-size: 14px;
    border-radius: 20px;
    height: 50px;
    color: #333333;
    background: #FFFFFF;
    border: unset;
    text-align: center;
    font-weight: bold;
    transition: all .3s
  }
  
  .section-evento-hoje .form .btn-submit {
    height: 50px;
    margin: auto;
    font-size: 18px;
    text-transform: unset;
    background: #50aa3e;
  }
  
  .section-evento-hoje .form .btn-submit:hover {
    height: 50px;
    margin: auto;
    font-size: 18px;
    text-transform: unset;
    background: #9ceb8c;
    color: darkgreen;
    transition: all .4s
  }
  
  .section-two-hora {
    min-height: 470px;
    height: 470px;
  }
  
  .section-two-hora .img-2 {
    margin: auto;
    width: 369px;
    height: 365px;
    margin-top:10%;
    /* background-image: url('/img/lp-banner-hora.png'); */
    background-image: url('/img/n-banner-nsctotal.png');
    background-repeat: round;
  }
  
  .section-two-hora .logos {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  .section-two-hora .section-two-hora-description {
    padding: 30px 16px;
    max-width: 512px;
  }
  
  .section-two-hora .section-two-hora-description h1 {
    font-size: 39px;
    color: #FF4100;
  }
  
  .section-two-hora .section-two-hora-description p {
    font-size: 16px;
    line-height: 22px;
  }
  
  .row {
    display: flex;
    flex-flow: row;
  }
  
  .collumn {
    width: 100%;
  }
  
  .title {
    padding-left: 22px;
  }
  
  .list li {
    padding-bottom: 10px;
  }
  
  @media(max-width: 500px) {
  
    .section-evento-hoje {
      background-image: url('/img/lp-banner-hora-1.png');
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-color: #147C00;
      top: calc(250px - 50vh);
      display: flex;
      justify-content: flex-end;
    }
  
    p {
        padding: 10px !important;
    }
  
    h1, h2 {
        padding: 10px !important;
    }
  
    button, input[type="text"] {
        width: 95% !important;
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
  
    .row {
        flex-flow: column;
    }
  
    .section, .section-evento-hoje, .section-two-hora {
        height: unset;
    }
  
    img {
        width: 100%;
    }
  
    .section-evento-hoje .img-1 {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: -1;
        width: 100%;
    }
  
    .section-evento-hoje .form {
        max-width: 100%;
        margin: auto;
    }
  
    .section-evento-hoje .form-box {
      height: 630px;
      background-color: #1a2926c7;
  }
  
    .section-two-hora .img-2 {
        width: 369px;
        height: 365px;
    }
  
  }
  

.terms {
  box-sizing: border-box;
  padding: 50px 15%;
}

.terms .title {
  box-sizing: border-box;
  padding: 0 40px;
  display: flex;
  position: relative;
}

.terms .title h1 {
  margin: 0;
  color: #ee6b25;
  font-size: 2.05rem;
  font-weight: 700;
  margin: 0 0 30px;
  text-align: left;
}

.terms .title .logo {
    position: absolute;
    top: 0;
    right: 40px;
    width: 64px;
  }

.terms .title .logo svg {
  fill: #949699;
}

.terms .text {
  border: 1px solid #717171;
  border-radius: 14px;
  box-sizing: border-box;
  color: #000;
  line-height: 1.2;
  padding: 46px 40px 23px;
  width: 100%;
}

.terms .text h2 {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0 0 7px;
  text-align: left;
}

.terms .text p {
  font-size: 1rem;
  font-weight: normal;
  margin: 0 0 20px;
}

.terms .logos {
  display: block;
  margin: 40px auto 0;
  max-height: 56px;
  max-width: 100%;
}

@media screen and (max-width: 800px) {

    .terms {
      padding: 40px 7%;
    }
}

@media screen and (max-width: 500px) {

  .terms {
    padding: 40px 25px;
  }

  .terms .title {
    padding: 0 25px;
  }

  .terms .title .logo {
    right: 25px;
  }

  .terms .text {
    padding: 30px 25px 15px;
  }
}
.confirm-signup .form .line {
    margin-top: 4%;
}

.confirm-signup .email-container {
    display: flex;
    justify-content: center;
    margin-top: 4vh;
    width: 90%;
    margin-left: auto;
}

.confirm-signup .email-container .email-text {
    color: #848585;
    font-size: 15px;
    margin-top: 1.3vh;
}

.confirm-signup .resend-container {
    display: flow-root;
    text-align: center;
    margin-top: 3vh;
    color: #848585;
}

.confirm-signup .resend-container a {
    font-weight: bold;
    color: #ee6b25;
}

.form .btn-submit {
    margin: 15px 0 10px;
}

.btn {
    background-color: #ee6b25;
    border: none;
    border-radius: 15px;
    color: #ffffff;
    cursor: pointer;
    font-weight: bold;
    margin: 0 10px 0;
    padding: 0.6rem;
    text-transform: uppercase;
    width: 90px;
    font-size: 12px;
}
.cancel-container {
    text-align: center;
    margin-top: 10px;
}

.cancel-container>.btn-cancel {
    color: #848585;
}

.change-mail .btn-submit {
    width: 80%;
    margin-left: 10%;
}
.loader-container {}
.icon-step {
    margin: auto;
    font-size: 19px;
    font-style: normal;
    font-weight: bold;
    font-family: Source Sans Pro, sans-serif;
}

.icon-step>img {
    margin-left: 4px;
}

@media only screen and (max-width: 720px) {
    .icon-step {
        font-size: 16px;
        margin-bottom: 3px;
    }
}

@media only screen and (max-width: 360px) {
    .icon-step>img {
        margin-bottom: 1px;
    }
}
header {
    display: flex;
    border-bottom: 1px solid #E1E0E0;
    background-color: white;
    left: 0;
    width: 100%;
    height: 100%;
    color: black;
}

.center-logo {
    margin: 10px auto 19px auto !important;
    position: relative !important;
}

.center-logo>img {
    margin-top: 20% !important;
}

header>.logo-wrapper {
    position: absolute;
    margin-left: 5%;
}

header>.logo-wrapper>img {
    margin-top: 28%;
}

.stepper-container {
    width: 45%;
    margin: 10px auto 0 auto;
}

@media only screen and (max-width: 870px) {
    .stepper-container {
        width: 60%;
        margin-left: 25%;
    }
}

@media only screen and (max-width: 720px) {
    header>.logo-wrapper>img {
        height: 32px;
        margin-top: 38%;
    }
}

@media only screen and (max-width: 360px) {
    .stepper-container {
        width: 80% !important;
    }
    header>.logo-wrapper>img {
        height: 25px;
        width: 70px;
        margin-top: 50% !important;
    }
}

@media only screen and (max-width: 320px) {
  .stepper-container {
    width: 100% !important;
    margin-left: 0%;
    margin: 60px auto 0 auto;
  }
  header>.logo-wrapper {
    left: 50%;
    margin-left: -27px;
  }
}

footer {
    display: flex;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #FF6915;
    color: #fff;
    height: 80px;
    z-index: 3;
}

footer .logo {
    width: 100%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
}

.logo>div>span {
    font-size: 12px;
    font-weight: bold;
}

.logo>img {
    margin: 2vh 0 1vh 0;
    width: auto;
}

@media only screen and (min-height: 800px) {
    footer {
        height: 12vh;
    }
}
.container {
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
}

.app {
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
}

.row {
  display: flex;
}

.row .col {
  box-sizing: border-box;
  position: relative;
  padding: 0 10px;
}

.row .col:first-child {
  padding-left: 0;
}

.row .col:last-child {
  padding-right: 0;
}

.row .col-1 {
  width: 8.33%;
}

.row .col-2 {
  width: 16.66%;
}

.row .col-3 {
  width: 25%;
}

.row .col-4 {
  width: 33.33%;
}

.row .col-5 {
  width: 41.67%;
}

.row .col-6 {
  width: 50%;
}

.row .col-7 {
  width: 58.33%;
}

.row .col-8 {
  width: 66.67%;
}

.row .col-9 {
  width: 75%;
}

.row .col-10 {
  width: 83.33%;
}

.row .col-11 {
  width: 91.67%;
}

.row .col-12 {
  width: 100%;
}

.form-group {
  padding: 12px 0;
  width: 100%;
}

.form-group:first-child {
  padding: 0 0 12px;
}

.form-group label {
  color: #000;
  font-size: 1.05rem;
  font-weight: 800;
  margin-bottom: 2px;
}


@media (max-width: 500px)
{
  .form-group label {
    margin-left: 10px;
  }
}

.form-group input[type="text"]:not(.pass),
.form-group input[type="password"]:not(.pass),
.form-group select {
  border: 1px solid #c0c0c0;
  border-radius: 2px;
  box-sizing: border-box;
  color: #858585;
  font-size: 1.05rem;
  padding: 0.5rem 0.9rem;
  width: 100%;
}

.form-group input.pass {
  border: none;
  height: 35px;
  width: 95%;
  font-size: 16px;
  color: #858585;
  outline: none;
}

.form-group input[type="text"]:focus,
.form-group input.pass:focus {
  outline: none;
}

.form-group input[type="text"]:not(.pass):focus,
.form-group .input-pass:focus-within {
  border: 2px solid black;
  border-radius: 4px;
}

.form-group .input-pass {
  display: flex;
  border: 1px solid #c0c0c0;
  border-radius: 2px;
  box-sizing: border-box;
  color: #858585;
  font-size: 1.05rem;
  padding: 0 0.9rem;
  width: 100%;
}

@media (max-width: 500px)
{
  .form-group .input-pass {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

.form-group .input-pass>svg {
  margin: 7px 0;
  cursor: pointer;
}

.form-group .input-pass>svg.animated {
  -webkit-animation: pulse 0.7s;
          animation: pulse 0.7s;
  border-radius: 50%;
}

.form-group input::-webkit-input-placeholder {
  color: #c7c7c7;
}

.form-group input::placeholder {
  color: #c7c7c7;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px gray;
  }

  100% {
    box-shadow: 0px 0px 5px 25px white;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px gray;
  }

  100% {
    box-shadow: 0px 0px 5px 25px white;
  }
}

.form-group .input-pass.error,
.form-group input[type="text"].error,
.form-group input[type="password"].error,
.form-group select.error {
  border: 1px solid #dc3535;
  margin-top: 1px;
}

.form-group .recaptcha {
  margin-top: 5px;
}

.error-message {
  padding: 4px 0;
  border-radius: 10px;
  margin: 4px 10px;
  background: #dc3535;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  float: none
}

.error-message::before {
  content: '\26A0';
  font-size: 14px;
  font-weight: 600;
  margin-right: 6px;
}

.form-group .custom-link {
  margin-top: 2px;
  float: right;
}

.btn-submit {
  background-color: #ee6b25;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  margin: 25px 0 0;
  padding: 0.95rem;
  text-transform: uppercase;
  width: 100%;
}

button.btn-submit:disabled {
  background-color: #858585;
}

.btn-submit:hover {
  background-color: #d66021;
}

.text-lower-case {
  text-transform: lowercase;
}

.text-lower-case::-webkit-input-placeholder {
  text-transform: none;
}

.text-lower-case::placeholder {
  text-transform: none;
}


@media(max-width: 500px) {
  .container {
    max-width: 500px;
    padding-right: 0!important;
    padding-left: 0!important;
    margin-right: 0!important;
    margin-left: 0!important;
  }

  .form-group .custom-link {
    padding-right: 10px;
  }
}

