.section {
    width: 100%;
    height: 527.75px;
}

.section-one .form-box {min-height: 444px;}

.bg-unisul {background-color: #1c408a;}

.section-one .img-1 {
    position: absolute;
    width: 445px;
}

.section-one .form {
  max-width: 400px;
  float: right;
  color: white;
  margin: 16px;
  margin-top: 40px;
  transition: all .3s
}

.section-one .form img {
    width: 167px;
}

.section-one .form h2 {
    font-size: 38px;
    margin: 15px 0px;
}

.section-one .form p {
    font-size: 18px;
}

.section-one .form .form-group input[type="text"] {
    border-radius: 20px;
    height: 50px;
    color: white;
    background: #5A6FA3;
    border: unset;
    text-align: center;
}

.section-one .form .btn-submit {
    height: 50px;
    margin: unset;
    font-size: 20px;
    text-transform: unset;
}

.section-two {
    min-height: 470px;
    height: 470px;
}

.section-two .img-2 {
    margin: auto;
    width: 500px;
    height: 353px;
    background-image: url('/img/lp-banner-2.png');
    background-repeat: round;
}

.section-two .logos {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.section-three .img-3 {
    margin: 61px auto 0px auto;
    width: 433px;
}

.section-three .section-three-description {
    padding-top: 105px;
    max-width: 527px;
    margin: auto;
    color: white;
}

.section-three .section-three-description h1 {
    font-size: 39px;
    margin: unset;
}

.section-three .section-three-description p {
    font-size: 16px;
    line-height: 22px;
}

.section-three .btn-submit {
    height: 50px;
    width: 380px;
    margin: 30px 0;
    font-size: 20px;
    background-color: #6F3989;
    text-transform: unset;
}

.section-three .btn-submit:hover {
    height: 50px;
    width: 380px;
    margin: 30px 0;
    background-color: #6F3989;
    text-transform: unset;
}

.section-four {
    padding-top: 95px;
    height: unset;
}

.section-four .img-4 {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 490px;
}

.section-four .section-four-description {
    max-width: 379px;
    margin-bottom: 20px;
    float: left;
}

.section-four .section-four-description h1 {
    font-size: 35px;
    margin: unset;
    color: #FF4100;
}

.section-four .section-four-description p {
    font-size: 16px;
    line-height: 22px;
}

.section-four .download-app p {
    margin: 10px 0px;
    font-size: 20px;
    font-weight: 700;
    color: #BEBEBE;
}

.section-four .download-app img {
    width: 124px;
    margin-right: 10px;
    height: 36px;
}

.row {
    display: flex;
    flex-flow: row;
}

.collumn {
    width: 100%;
}

.title {
    padding-left: 22px;
}

.list li {
    padding-bottom: 10px;
}

@media(max-width: 500px) {

    p {
        padding: 10px !important;
    }

    h1, h2 {
        padding: 10px !important;
    }

    button, input[type="text"] {
        width: 95% !important;
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;

    }

    .row {
        flex-flow: column;
    }

    .section, .section-one, .section-two, .section-three, .section-four {
        height: unset;
    }

    img {
        width: 100%;
    }

    .section-one .img-1 {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: -1;
        width: 100%;
    }

    .section-one .form {
        max-width: 100%;
        margin-right: unset;
    }

    .section-one .form-box {
        height: 570px;
        background-color: #1c408a7a;
    }

    .section-one .form img {
        width: 95%;
        display: block;
        margin: auto;
    }

    .section-two .img-2 {
        width: auto;
        height: 300px;
    }

    .section-three {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
    }

    .section-three .img-3 {
        margin: 61px auto -4px auto;
        width: 100%;
    }

    .section-three .btn-submit {
        width: 100%;
    }

    .section-four {
        flex-flow: column-reverse;
    }

    .section-four .img-4 {
        width: 100%;
    }

    .section-four .section-four-description {
        max-width: unset;
    }

    .section-four .download-app .row {
        flex-flow: row;
    }

    .section-four .download-app p {
        margin: unset;
    }

    .section-four .download-app {
        padding-left: 20px;
    }

}
