.checkbox {
  display: inline-block;
  margin: 0 0 10px 0;
}

.checkbox.large {
  margin: 10px 0 5px 0;
}

.checkbox input {
  display: none;
  height: 0;
  width: 0;
}

.checkbox label {
  font-size: 1rem;
  color: #8e8e8e;
  cursor: pointer;
  user-select: none;
}

.checkbox.large label {
  font-size: 1.05rem;
}

.checkbox label a {
  color: #0068f0;
}

.checkbox label .check {
  border: 1px solid #C0C0C0;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 14px;
  margin: 0 5px -1px 0;
  position: relative;
  width: 14px;
}

.checkbox.large label .check {
  border-radius: 3px;
  height: 20px;
  margin: 0 7px -4px 0;
  position: relative;
  width: 20px;
}

.checkbox.error label .check {
  border-color: #dc3535;
}

.checkbox input:checked + label .check:after {
  content: '';
  position: absolute;
  left: 1px;
  top: 5px;
  background: #555555;
  width: 2px;
  height: 2px;
  box-shadow:
    2px 0 0 #555555,
    4px 0 0 #555555,
    4px -2px 0 #555555,
    4px -4px 0 #555555,
    4px -6px 0 #555555,
    4px -8px 0 #555555;
  transform: rotate(45deg);    
}

.checkbox.large input:checked + label .check:after {
  left: 4px;
  top: 8px;
  background: #555555;
  box-shadow: 
    2px 0 0 #555555,
    4px 0 0 #555555,
    4px -2px 0 #555555,
    4px -4px 0 #555555,
    4px -6px 0 #555555,
    4px -8px 0 #555555;
  transform: rotate(45deg);    
}
