.signup .terms-message {
    color: #999999;
    font-size: 0.95rem;
    margin: 15px 0 5px;
}

.signup .terms-message a {
    color: #00C8FE;
    text-decoration: none;
}

.signup .user-exists {
    margin: 20px 0 0;
    color: #dc3535;
    font-size: 1rem;
}

.signup .user-exists a {
    color: #0068f0;
}

.signup .account-confirmation-text {
    text-align: left;
    color: #999999;
    margin-bottom: 1vh;
    font-size: 1.0rem;
    font-weight: 500;
    margin-top: 6px;
}

.signup .alinhamento {
    padding: 0;
    margin: 0;
}