.confirm-signup .form .line {
    margin-top: 4%;
}

.confirm-signup .email-container {
    display: flex;
    justify-content: center;
    margin-top: 4vh;
    width: 90%;
    margin-left: auto;
}

.confirm-signup .email-container .email-text {
    color: #848585;
    font-size: 15px;
    margin-top: 1.3vh;
}

.confirm-signup .resend-container {
    display: flow-root;
    text-align: center;
    margin-top: 3vh;
    color: #848585;
}

.confirm-signup .resend-container a {
    font-weight: bold;
    color: #ee6b25;
}

.form .btn-submit {
    margin: 15px 0 10px;
}

.btn {
    background-color: #ee6b25;
    border: none;
    border-radius: 15px;
    color: #ffffff;
    cursor: pointer;
    font-weight: bold;
    margin: 0 10px 0;
    padding: 0.6rem;
    text-transform: uppercase;
    width: 90px;
    font-size: 12px;
}