.links-container {
    display: flex;
    margin: 7% auto 7%;
}

.link-wrapper {
    display: flex;
    font-size: 1.3rem;
    margin: auto;
    color: #000;
}

/* @media only screen and (max-width: 480px) {
    .links-container {
        display: block;
    }
} */

@media only screen and (max-width: 320px) {
    .link-wrapper {
        font-size: 1rem;
        text-align: center;
        display: block;
    }
    .link-assinante {
        margin-left: 29%;
        font-size: 1rem;
    }
}

.call-us{
    margin-top: 1rem;
    text-align: center;
}

.link-whatsapp{
    color: #ee6b25;
    text-decoration: underline;
    font-size: 0.9rem;
}

.whatsapp{
    text-align: center;
    padding: 0.7rem;
}