.container {
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
}

.app {
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
}

.row {
  display: flex;
}

.row .col {
  box-sizing: border-box;
  position: relative;
  padding: 0 10px;
}

.row .col:first-child {
  padding-left: 0;
}

.row .col:last-child {
  padding-right: 0;
}

.row .col-1 {
  width: 8.33%;
}

.row .col-2 {
  width: 16.66%;
}

.row .col-3 {
  width: 25%;
}

.row .col-4 {
  width: 33.33%;
}

.row .col-5 {
  width: 41.67%;
}

.row .col-6 {
  width: 50%;
}

.row .col-7 {
  width: 58.33%;
}

.row .col-8 {
  width: 66.67%;
}

.row .col-9 {
  width: 75%;
}

.row .col-10 {
  width: 83.33%;
}

.row .col-11 {
  width: 91.67%;
}

.row .col-12 {
  width: 100%;
}

.form-group {
  padding: 12px 0;
  width: 100%;
}

.form-group:first-child {
  padding: 0 0 12px;
}

.form-group label {
  color: #000;
  font-size: 1.05rem;
  font-weight: 800;
  margin-bottom: 2px;
}


@media (max-width: 500px)
{
  .form-group label {
    margin-left: 10px;
  }
}

.form-group input[type="text"]:not(.pass),
.form-group input[type="password"]:not(.pass),
.form-group select {
  border: 1px solid #c0c0c0;
  border-radius: 2px;
  box-sizing: border-box;
  color: #858585;
  font-size: 1.05rem;
  padding: 0.5rem 0.9rem;
  width: 100%;
}

.form-group input.pass {
  border: none;
  height: 35px;
  width: 95%;
  font-size: 16px;
  color: #858585;
  outline: none;
}

.form-group input[type="text"]:focus,
.form-group input.pass:focus {
  outline: none;
}

.form-group input[type="text"]:not(.pass):focus,
.form-group .input-pass:focus-within {
  border: 2px solid black;
  border-radius: 4px;
}

.form-group .input-pass {
  display: flex;
  border: 1px solid #c0c0c0;
  border-radius: 2px;
  box-sizing: border-box;
  color: #858585;
  font-size: 1.05rem;
  padding: 0 0.9rem;
  width: 100%;
}

@media (max-width: 500px)
{
  .form-group .input-pass {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

.form-group .input-pass>svg {
  margin: 7px 0;
  cursor: pointer;
}

.form-group .input-pass>svg.animated {
  animation: pulse 0.7s;
  border-radius: 50%;
}

.form-group input::placeholder {
  color: #c7c7c7;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px gray;
  }

  100% {
    box-shadow: 0px 0px 5px 25px white;
  }
}

.form-group .input-pass.error,
.form-group input[type="text"].error,
.form-group input[type="password"].error,
.form-group select.error {
  border: 1px solid #dc3535;
  margin-top: 1px;
}

.form-group .recaptcha {
  margin-top: 5px;
}

.error-message {
  padding: 4px 0;
  border-radius: 10px;
  margin: 4px 10px;
  background: #dc3535;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  float: none
}

.error-message::before {
  content: '\0026A0';
  font-size: 14px;
  font-weight: 600;
  margin-right: 6px;
}

.form-group .custom-link {
  margin-top: 2px;
  float: right;
}

.btn-submit {
  background-color: #ee6b25;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  margin: 25px 0 0;
  padding: 0.95rem;
  text-transform: uppercase;
  width: 100%;
}

button.btn-submit:disabled {
  background-color: #858585;
}

.btn-submit:hover {
  background-color: #d66021;
}

.text-lower-case {
  text-transform: lowercase;
}

.text-lower-case::-webkit-input-placeholder {
  text-transform: none;
}

.text-lower-case:-moz-placeholder {
  text-transform: none;
}

.text-lower-case::-moz-placeholder {
  text-transform: none;
}

.text-lower-case:-ms-input-placeholder {
  text-transform: none;
}

.text-lower-case::placeholder {
  text-transform: none;
}


@media(max-width: 500px) {
  .container {
    max-width: 500px;
    padding-right: 0!important;
    padding-left: 0!important;
    margin-right: 0!important;
    margin-left: 0!important;
  }

  .form-group .custom-link {
    padding-right: 10px;
  }
}
