.box {
    padding: 10px;
    position: relative;
    max-width: 478px;
    min-height: calc(77vh - 70px);
    margin: 5vh auto;
}

.box>.back {
    position: absolute;
    top: 10px;
    left: 22px;
}

.box>.back svg {
    fill: #949699;
    height: 41.66px;
    /* IE */
    width: 72px;
}

.box>.back>a>span {
    position: absolute;
    top: 14px;
    left: 35px;
    color: #848585;
    font-size: 12px;
    font-weight: bold;
}

.box>.container {
    border: 1px solid #9b9b9b;
    border-radius: 14px;
    box-sizing: border-box;
    padding: 15px 40px 18px;
    width: 100%;
}

.box>.container>.title-wrapper {
    display: flex;
}

.box>.container>.title-wrapper>div {
    display: flex;
    width: 100%;
}

.box>.container>.title-wrapper>div>h1 {
    margin-left: auto;
    float: right;
}

.box>.container>.title-wrapper>div>img {
    margin: 17px auto auto 10px;
    float: left;
}

.box>.container>.title-wrapper>div>h1.center {
    margin-right: auto;
}



.message-container>.message {
    color: #FF6915;
    font-size: 16px;
    width: 90%;
    margin: auto;
    padding-bottom: 2%;
    text-align: center;
    padding-left: 6px;
}

.success-icon {
    background: url("/icons/success-icon.svg");
    height: 19px;
    width: 18px;
    display: inline-block;
    position: absolute;
}

.error-icon {
    background: url("/icons/error-icon.svg");
    height: 19px;
    width: 18px;
    display: inline-block;
    position: absolute;
}

@media screen and (max-width: 720px) {
    .box {
        min-height: 77vh;
    }
}

@media screen and (max-width: 550px) {
    .box>.container>.title-wrapper>div>img {
        display: block;
        margin: auto;
        max-width: 125px;
    }
    .box {
        margin: 0 auto;
        padding: 20px;
    }
    .box>.logo {
        top: 35px;
        right: 35px;
    }
    .box>.container {
        padding: 36px 25px 12px;
    }
}

@media screen and (max-width: 400px) {
    .box>.logo {
        top: 25px;
        right: 25px;
    }
    .box {
        padding: 10px;
    }
    .box>.container {
        padding: 36px 20px 12px;
    }
}

.box>.footer {
    box-sizing: border-box;
    font-size: 0.9rem;
    padding: 4px 15px 10px;
    text-align: right;
    width: 100%;
    display: flex;
}

.box>.footer .left {
    text-align: left;
    width: 50%;
}

.box>.footer .right {
    text-align: right;
    width: 50%;
}

.box>.footer .link {
    color: #858585;
    font-weight: bold;
    text-decoration: none;
}

.box h1 {
    color: #ee6b25;
    font-size: 2.05rem;
    font-weight: 700;
    margin: 10px 0 22px;
    text-align: center;
}

.box h2 {
    color: #000;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0 0 10px;
    text-align: center;
    width: 95%;
}

.center360 {
    margin-left: inherit;
}